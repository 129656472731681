import {Link} from 'react-router-dom';
import {showPrice, showDateTime, showPercent, showStrikePrice, showAmount} from '../../libs/util';
import CurrencyIcon from '../../components/common/icon';

const Component = ({plan, onShow}) => {

  const isPut = plan.product.option && plan.product.option.option_type == 'PUT';
  const isOption = plan.product.product_type === "OPTION";

  const getTitle = () => {
    let title = plan?.product?.description || "";
    if (title.includes("DCI")) {
        title = title.replace(/USD-[CP]/, "USDC");
    }
    if(plan?.option_list){
      const quoteCurrency = plan.option_list[0].quoteCurrency;
      title = `${plan?.product?.description} - (with ${quoteCurrency} Option)`
    }

    return title;
  }

    if (plan?.status != 'COMPLETED') {
        return (
            <div className="nk-odr-item">
                <div className="nk-odr-col">
                    <div className="nk-odr-info">
                        <div className="nk-odr-badge">
                            {isPut ?
                                (<CurrencyIcon currency={plan.product.option.quote_currency.toLowerCase()}/>) : (
                                    <CurrencyIcon currency='USDC'/>)
                            }
                        </div>
                        <div className="nk-odr-data">
                            <div className="nk-odr-label">
                                {getTitle()}

                            </div>
                            <div className="nk-odr-meta">
                                <span className="date">{plan?.reference_number}</span>
                            </div>
                            <div className="nk-odr-meta">
              <span className="date">
                Invested: 
              </span>
                                <span className="date">
                                {plan?.term_expired_amount_ccy === "USD" ? 
                        showPrice(plan?.term_expired_amount, plan?.term_expired_amount_ccy)+ " ("+showPrice(plan?.contributionAmount, plan?.contributionAmountCcy) + ")"
                        :
                                    showAmount(plan?.invest_amount, null, 8, false) + " " + plan?.invest_ccy
                        }
                                </span>
                                <span className={(plan?.order_status != "LAPSED" && plan?.order_status != "EXERCISED" ) ? "status text-dark": "status text-success"}>
                                {plan?.order_status}
                            </span>


                            </div>
                            <div className="nk-odr-meta">
                                <span className="date">APR:</span>
                                <span className="date">{showPercent(plan?.term_apy)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-odr-col nk-odr-col-amount">
                    <div className="nk-odr-label">{showDateTime(plan?.value_datetime)}</div>
                    <div className="nk-odr-meta">Invest Date</div>
                </div>
                <div className="nk-odr-col nk-odr-col-amount">
                    <div className="nk-odr-label">{showDateTime(plan?.maturity_datetime)}</div>
                    <div className="nk-odr-meta">Maturity Date</div>
                </div>

                <div className="nk-odr-col nk-odr-col-amount text-center">
                    {isOption &&  
                        <div className="nk-odr-meta">if Reference
                            Rate {isPut ? String.fromCharCode(8805) : String.fromCharCode(8804)} {showStrikePrice(plan?.product?.option?.strike_price, 'USD')}
                        </div>
                    }
                    <div className="text-center"><strong
                        className="text-dark">{showPrice(plan?.term_expired_amount, plan?.term_expired_amount_ccy)}</strong>
                    </div>
                    <div className="nk-odr-meta">Return</div>
                </div>

                {plan?.term_exercised_amount && <div className="nk-odr-col nk-odr-col-amount text-center">
                   {isOption &&  <div className="nk-odr-meta">if Reference
                        Rate {isPut ? String.fromCharCode(60) : String.fromCharCode(62)} {showStrikePrice(plan?.product?.option?.strike_price, 'USD')}
                    </div>}
                    <div className=""><strong
                        className="text-dark">{showPrice(plan?.term_exercised_amount, plan?.term_exercised_amount_ccy)}</strong>
                    </div>
                    <div className="nk-odr-meta">Return</div>
                </div>}

                <div className="nk-odr-col nk-odr-col-action">
                    <div className="nk-odr-action">
                        <Link className="tnx-details" to={"/portal/investment/order/" + plan.transaction_id}><em
                            className="icon ni ni-forward-ios"></em></Link>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="nk-odr-item">
                <div className="nk-odr-col">
                    <div className="nk-odr-info">
                        <div className="nk-odr-badge">
                            {isPut ?
                                (<CurrencyIcon currency={plan.product.option.quote_currency}/>) : (
                                    <CurrencyIcon currency='USDC'/>)
                            }
                        </div>
                        <div className="nk-odr-data">
                            <div className="nk-odr-label">
                                {plan?.product?.description}

                            </div>
                            <div className="nk-odr-meta">
                                <span className="date">{plan?.reference_number}</span>
                            </div>
                            <div className="nk-odr-meta">
                <span className="date">Invested:</span>
                    <span className="date">
                    {plan?.term_expired_amount_ccy === "USD" ? 
                        showPrice(plan?.term_expired_amount, plan?.term_expired_amount_ccy)+ " ("+showPrice(plan?.contributionAmount, plan?.contributionAmountCcy) + ")"
                        : showPrice(plan?.invest_amount, plan?.invest_ccy)
                        }
                    </span>
                    <span className="status text-danger">
                        {plan?.order_status}
                    </span>
                            </div>
                            <div className="nk-odr-meta">
                                <span className="date">APR:</span>
                                <span className="date">{showPercent(plan?.term_apy)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-odr-col nk-odr-col-amount">
                    <div className="nk-odr-label">{showDateTime(plan?.value_datetime)}</div>
                    <div className="nk-odr-meta">Invest Date</div>
                </div>
                <div className="nk-odr-col nk-odr-col-amount">
                    <div className="nk-odr-label">{showDateTime(plan?.maturity_datetime)}</div>
                    <div className="nk-odr-meta">Maturity Date</div>
                </div>

                <div className="nk-odr-col nk-odr-col-amount">
                    <div className="nk-odr-label">{showDateTime(plan?.redemption_datetime)}</div>
                    <div className="nk-odr-meta">Redemption Date</div>
                </div>

                <div className="nk-odr-col nk-odr-col-amount text-center">
                    <div className="nk-odr-meta">Return</div>
                    <div className=""><strong
                        className="text-dark">{showPrice(plan?.redeem_amount, plan?.redeem_amount_ccy)}</strong>
                    </div>

                </div>

                <div className="nk-odr-col nk-odr-col-action">
                    <div className="nk-odr-action">
                        <Link className="tnx-details" to={"/portal/investment/order/" + plan.transaction_id}><em
                            className="icon ni ni-forward-ios"></em></Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Component;
