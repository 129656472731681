import { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layouts/user";
import UserHead from "../../components/user/head";
import { InfoAlert, WarnAlert } from "../../components/user/alert";
import Card from "../../components/invest/card";
import InvestedPlans from "../../components/invest/investedPlans";
import { post, download, get, upload } from "../../libs/api";
import {showPrice, showPercent, showDateTime, showAmount} from "../../libs/util";
import { Errors, Successes } from "../../components/common/msg";
import UserContext from "../../libs/context";
import { NumericFormat } from "react-number-format";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

function Page({ isNew }) {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [order, setOrder] = useState();
  const { notification } = useContext(UserContext);
  const [isRolled, setIsRolled] = useState(false);
  const [rolloverAmt, setrolloverAmt] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [showRateChangeModal, setShowRateChangeModal] = useState(false);
  const [currentCommsRate, setCurrentCommsRate] = useState(0);
  const [newCommissionRate, setNewCommissionRate] = useState("");
  const [attachment, setAttachment] = useState(null);
  const AGENT_REQUEST_CATEGORY = 12;

  const loadOrder = async () => {
    setLoading(true);
    const data = await post("/investment/order/get", { orderId });
    setLoading(false);
    if (data?.status != 200) {
      setErrors([data?.error]);
      return;
    }

    setOrder(data.data);
    if (data.data) {
      setrolloverAmt(data.data.term_expired_amount);
      setCurrentCommsRate(data.data.commsRate);
      setIsRolled(true);
    }

    if (isNew) {
      setSuccesses([
        "Your order to invest in this product has been submitted.",
      ]);
    }

    // Set canEdit inside loadOrder after the order has been fetched.
    const currentTime = new Date(); // Current time in UTC

    // Add 'Z' to interpret the time as UTC
    const maturityTime = new Date(data.data.maturity_datetime + "Z");

    const timeDifferenceInMs = maturityTime.getTime() - currentTime.getTime();
    const differenceInHours = timeDifferenceInMs / 36e5; // Convert from milliseconds to hours.

    console.log(">>!> currentTime:", currentTime);
    console.log("<<!< maturityTime:", maturityTime);

    setCanEdit(differenceInHours >= 24);
  };

  const handleAgentRateSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setSuccesses([]);
    setLoading(true);
    if (!attachment) {
      setErrors(['Please upload a file before submitting.']);
      return;
    }

    let fileId1;
    try {
      const uploadData = await upload("/wallet/file/upload", attachment, true, AGENT_REQUEST_CATEGORY);
      if (uploadData?.status !== 200) {
        throw new Error("Error uploading file");
      }
      fileId1 = uploadData.data.file_id;
      console.log("attachment uploaded");

      const params = {
        comms_rate: newCommissionRate,
        transaction_id: order.transaction_id,
        client_id: order.client_id,
        att1_file_id: fileId1,
      };

      const data = await post("/wallet/request/agent_rate", params);
      setLoading(false);
      if (data?.status !== 200) {
        setErrors([data.error]);
      } else {
        setSuccesses(["Agent rate change submitted successfully."]);
      }
    } catch (error) {
      setErrors([error.message]);
    }
    setShowRateChangeModal(false);
  };

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    if (notification?.type == 2) {
      // order approved
      console.log("!!!  order approved", notification?.type);
      refreshOrder();
    }
  }, [notification]);

  const refreshOrder = async () => {
    await loadOrder();
    setSuccesses([]);
  };

  const handleRefresh = async (e) => {
    e.preventDefault();
    refreshOrder();
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    const blob = await download("/investment/order/pdf", { orderId });
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", order?.file_name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  function handleAmountChange(values) {
    const { value } = values;
    setrolloverAmt(value);
  }

  const handleSave = async () => {
    setLoading(true);
    console.log(">>>> rolloverAmt is " + rolloverAmt);
    if (isRolled) {
      if (
        rolloverAmt < order.product.min_amount ||
        rolloverAmt > order.term_expired_amount
      ) {
        let errorMessage = `Amount must be between ${order.product.min_amount} and ${order.term_expired_amount}`;
        console.log(">>> " + errorMessage);

        setErrors([errorMessage]);
        // setLoading(false);
        return;
      }
    }

    const data = await post("/investment/cc_order/edit", {
      transaction_id: orderId,
      rollover_amt: rolloverAmt,
    });
    setLoading(false);
    if (data?.status != 200) {
      setErrors([data?.error]);
      return;
    }
    setSuccesses(["Rollover has been updated successfully."]);
    setIsEditMode(false);
    loadOrder();
  };

  const handleIsRolledChange = () => {
    if (isRolled) {
      setrolloverAmt(0);
    }
    setIsRolled(!isRolled);
  };

  const isPut = order?.product?.option?.option_type == "PUT";

  const formatNumber = (numString) => {
    // strip out all non-digit characters
    const stripped = numString.replace(/\D/g, "");
    // perform the formatting
    return stripped.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const handlerolloverAmtChange = (e) => {
    const formattedValue = formatNumber(e.target.value);
    setrolloverAmt(formattedValue);
  };

  const getTitle = () => {
    let title = order?.product?.description || "";;
    if (title.includes("DCI")) {
      title = title.replace(/USD-[CP]/, "USDC");
  }
    if (order?.option_list) {
      const quoteCurrency = order.option_list[0].quoteCurrency;
      title = `${order?.product?.description} - (with ${quoteCurrency} Option)`;
    }
    return title;
  };

  return (
    <Layout menu="investment">
      <UserHead
        sub="Investment"
        title={getTitle()}
        showBack={true}
        backTo="/portal/investment"
      >
        {order && (
          <ul className="nk-block-tools gx-3">
            <li>
              <a
                href="#"
                className="btn btn-light btn-white"
                onClick={handleRefresh}
              >
                <span>Refresh</span>
              </a>
            </li>
            {order.file_id && (
              <li>
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={handleDownload}
                >
                  <span>Download</span>
                </a>
              </li>
            )}
            {/* {!isEditMode && canEdit && (
              <li>
                <a href="#" className="btn btn-primary" onClick={handleEdit}>
                  <span>Rollover</span>
                </a>
              </li>
            )} */}
            {currentCommsRate && !(order?.product?.description || "").includes("DCI") && (
              <li>
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={() => setShowRateChangeModal(true)}
                >
                  <span>Request Rate Change</span>
                </a>
              </li>
            )}
          </ul>
        )}
      </UserHead>

      <Successes msgs={successes} />

      <div className="mt-1 mb-4">
        <span>{order?.reference_number}</span>
        <span
          className="ml-4"
          style={{ color: order?.order_status === "PENDING" ? "red" : "inherit" }}
          >
          {order?.order_status}
        </span>      
      </div>

      {order && (
        <>
          <div className="nk-odr-list is-stretch card card-bordered ">
            <div className="row p-2 border-bottom">
              <div className="col-12 nk-odr-label">
              {order.term_expired_amount_ccy === "USD" ?
                showPrice(order.term_expired_amount, order.term_expired_amount_ccy, null, true)+ " ("+showPrice(order.contributionAmount, order.contributionAmountCcy, null, true) + ")"
                : showAmount(order.invest_amount, null, 8, false) + " " + order.invest_ccy }
              </div>
              <div className="col-12 nk-odr-meta">Invested</div>
            </div>

            {order.product?.option?.strike_price && (
              <div className="row p-2 border-bottom">
                <div className="col-4">&nbsp;</div>
                <div className="col-4">
                  <span className="float-left">Strike Rate: </span>
                  <span className="float-right">
                    {showPrice(order.product?.option?.strike_price, "USD")}
                  </span>
                </div>
                <div className="col-4">&nbsp;</div>
              </div>
            )}

            <div className="row p-2 border-bottom">
              <div className="col-4">
                <span className="float-left">Tenure: </span>
                <span className="float-right">{order.tenor} days</span>
              </div>
              <div className="col-4">
                <span className="float-left">
                  {order.option_list
                    ? "Desired Fixed Rate:"
                    : "Funding Rate:"}
                </span>
                <span className="float-right">
                  {showPercent(order.term_apy)}
                </span>
              </div>
              {/* <div className="col-4">
                <span className="float-left">Maturity Date: </span>
                <span className="float-right">
                  {order.redemption_datetime
                    ? showDateTime(order.redemption_datetime)
                    : "TBA"}
                </span>
              </div> */}
                <div className="col-4">
                <span className="float-left">Maturity Date: </span>
                <span className="float-right">
                  {showDateTime(order.maturity_datetime)}
                </span>
              </div>
            </div>

            <div className="row p-2 border-bottom">
              {order.option_list ? (
                <div className="col-4">
                  <span className="float-left">Participation Rate: </span>
                  <span className="float-right">
                    {parseFloat(order.participation_rate) * 100}%
                  </span>
                </div>
              ) : (
                <div className="col-4"></div>
              )}
              <div className="col-4 ">
                {order.product.product_type == "CASH_CARRY" ? (
                  <span className="float-left">Return: </span>
                ) : (
                  <span className="float-left">
                    Final Notional Exchange (Final Index Level{" "}
                    {isPut
                      ? String.fromCharCode(8805)
                      : String.fromCharCode(8804)}{" "}
                    Strike):{" "}
                  </span>
                )}
                <span className="float-right">
                  {showPrice(
                    order.term_expired_amount,
                    order.term_expired_amount_ccy, null, true
                  )}
                </span>
              </div>
              {/* <div className="col-4 ">
                <span className="float-left">Reference Rate: </span>
                <span className="float-right">
                  {order.payment_reference ? order.payment_reference : "TBA"}
                </span>
              </div> */}
              <div className="col-4 ">
                <span className="float-left">Trade Date: </span>
                <span className="float-right">
                  {showDateTime(order.value_datetime)}
                </span>
              </div>
            </div>

             {/*<div className="row p-2 border-bottom">
              
             
             {order.term_exercised_amount ? (
                <div className="col-4">
                  <span className="float-left">
                    Return (Reference Rate{" "}
                    {isPut ? String.fromCharCode(60) : String.fromCharCode(62)}{" "}
                    Strike):{" "}
                  </span>
                  <span className="float-right">
                    {showPrice(
                      order.term_exercised_amount,
                      order.term_exercised_amount_ccy
                    )}
                  </span>
                </div>
              ) : (
                <div className="col-4">
                  <span className="float-left">Redemption Amount: </span>
                  <span className="float-right">
                    {order.redeem_amount
                      ? showPrice(order.redeem_amount, order.redeem_amount_ccy)
                      : "TBA"}
                  </span>
                </div>
              )} 
            </div> */}

            {order.option_list && (
              <div>
                <div className="nk-pps-title title mt-2 mb-1 pl-2">
                  Options:
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Direction</th>
                      <th>From Market Price</th>
                      <th>Strike</th>
                      <th>Notional Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.option_list?.map((option, index) => (
                      <tr key={index}>
                        <td>{option.optionType}</td>
                        <td>{option.direction}</td>
                        <td>{parseFloat(option.mktPctStrike) * 100}%</td>
                        <td>
                          {showPrice(option.strikePrice, order.invest_ccy, 0, true)}
                        </td>
                        <td>
                          {showPrice(order.invest_amount, order.invest_ccy, null, true)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* <div className="mt-3">Rollover</div>
          <div className="nk-odr-list is-stretch card card-bordered ">
            <div className="row p-2 border-bottom">
              <div className="col-4">
                <span className="float-left">Rollover Update Date: </span>
                <span className="float-right">
                  {order.rollover_time
                    ? showDateTime(order.rollover_time)
                    : "NA"}
                </span>
              </div>
              <div className="col-4">
                <span className="float-left">Rollover Amount: </span>
                <span className="float-right">
                  {order.rollover_amt
                    ? showPrice(
                        order.rollover_amt,
                        order.term_expired_amount_ccy
                      )
                    : "0"}
                </span>
              </div>
            </div>
            {isEditMode && (
              <div className="row p-2 border-bottom">
                <div className="col-4 d-flex align-items-baseline">
                  <input
                    type="checkbox"
                    checked={isRolled}
                    onChange={handleIsRolledChange}
                  />
                  <label>&nbsp; Rollover</label>
                </div>
                <div className="col-4 d-flex align-items-baseline justify-content-between">
                  <label style={{ marginRight: "auto" }}>
                    <span>Rollover Amount: </span>
                  </label>
                  <NumericFormat
                    value={rolloverAmt}
                    thousandSeparator={true}
                    onValueChange={handleAmountChange}
                    disabled={!isRolled}
                  />
                  <span className="float-right">
                    &nbsp; {order.term_expired_amount_ccy}
                  </span>
                  {errors?.length > 0 && <Errors errors={errors} />}
                </div>
                <div className="col-4 d-flex align-items-baseline">
                  <button
                    className="btn btn-light btn-white"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                </div>
              </div>
            )}
          </div> */}
          {order.commsRate && !(order?.product?.description || "").includes("DCI") && (
            <>
            <div className="mt-3">Commission</div>
            <div className="nk-odr-list is-stretch card card-bordered ">
              <div className="row p-2 border-bottom">
                <div className="col-4">
                  <span className="float-left">Commission rate: </span>
                  <span className="float-right">{order.commsRate}</span>
                </div>
              </div>
            </div>
            </>
          )}
        </>
      )}

      <Modal
        show={showRateChangeModal}
        onHide={() => setShowRateChangeModal(false)}
      >
        <div className="modal-header">
          <div className="modal-title h4">Agent Rate Change Request</div>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowRateChangeModal(false)}
          >
            X
          </button>
        </div>
        <Modal.Body>
          <Form onSubmit={handleAgentRateSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="currentCommissionRate">
                  <Form.Label>Current Commission Rate</Form.Label>
                  <Form.Control
                    type="number"
                    value={currentCommsRate}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="newCommissionRate">
                  <Form.Label>New Commission Rate</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter new commission rate"
                    value={newCommissionRate}
                    required
                    onChange={(e) => setNewCommissionRate(e.target.value)}
                    isInvalid={
                      parseFloat(newCommissionRate) >=
                      parseFloat(currentCommsRate)
                    } 
                  />
                  <Form.Control.Feedback type="invalid">
                    New commission rate must be smaller than the current rate.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col md={6}>
                <Form.Group controlId="fileUpload">
                  <Form.Label>Upload Attachment</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setAttachment(e.target.files[0])}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>     
            <Button variant="primary" type="submit" className="mt-2">
              Submit
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
export default Page;
